<template>
  <div class="recycle">
    <!-- 清空回收站按钮 -->
    <el-row id="header" style="margin-top: 35px">
      <el-col
        style="float: right; text-align: center; margin-right: 2vw"
        class="header"
        :span="3"
      >
        <el-button @click="Delete('','您确定要清空回收站？')" :disabled="listData.length?false:true" :class="listData.length?'':'opacity'">清空回收站</el-button>
      </el-col>
    </el-row>
    <!-- 回收站内容 -->
    <div class="context">
      <!-- 头部行 -->
      <el-row class="headtitle" :gutter="10">
        <!-- 素材列 -->
        <el-col :span="6">
          <span class="rourse">素 材</span>
        </el-col>
        <!-- 格式列 -->
        <el-col :span="3">
          <span>格 式</span>
        </el-col>
        <!-- 基本信息列 -->
        <el-col :span="4">
          <span>大小</span>
        </el-col>
        <!-- 删除时间列 -->
        <el-col :span="7">
          <span>删除时间</span>
        </el-col>
        <!-- 操作列 -->
        <el-col :span="4">
          <span>操 作</span>
        </el-col>
        <el-divider></el-divider>
      </el-row>
      <!-- 分割线 -->
      <!-- 内容行的容器 -->
      <div class="contextcontainer" ref="scrollContent">
        <!-- 内容行 -->
        <div class="contextItme" v-for="(item,i) in listData" :key="i">
          <!-- 内容 -->
          <el-row id="item" :gutter="10">
            <!-- 素材列 -->
            <el-col :span="6">
              <!-- 视频跟文字 -->
              <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom"  popper-class="recycle_tooltip">
                <span class="metirial_name one_line_ellipsis">{{item.name}}</span>
              </el-tooltip>
            </el-col>
            <!-- 格式列 -->
            <el-col :span="3">
              <span>{{item.format}}</span>
            </el-col>
            <!-- 基本信息列 -->
            <el-col :span="4" style="line-height: 25px">
              <p style="padding-left: 10px">{{item.size | transformByte}}</p>
              <!-- <p style="padding-left: 10px">{{item.resolution}}</p> -->
              <!-- <p style="padding-left: 10px" v-if="item.type == 1">{{item.duration}}</p> -->
            </el-col>
            <!-- 删除时间列 -->
            <el-col :span="7">
              <span>{{item.delete_time | formatTime}}</span>
            </el-col>
            <!-- 操作列 -->
            <el-col :span="4">
              <!-- 还原 -->
              <el-tooltip v-delTabIndex effect="dark" content="恢复" placement="top">
                <img style="width: 16px;height: 16px;margin-left: 30px;cursor: pointer;" src="@/assets/images/return.png" alt="" @click="recoverFile(item.ip_id)"/>
              </el-tooltip>
              <!-- 删除 -->
              <el-tooltip v-delTabIndex effect="dark" content="删除记录" placement="top">
                <img @click="Delete(item.ip_id,'您确定要删除此记录吗？')" style="width: 16px;height: 16px;margin-left: 30px;cursor: pointer;" src="@/assets/images/delete.png" alt=""/>
              </el-tooltip>
            </el-col>
          </el-row>
          <!-- 分割线 -->
          <el-divider></el-divider>
        </div>
        <noResult v-if="listData.length == 0 && judgeBottom == 0" iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无数据"></noResult>
        <!-- <div>
          <span v-if="judgeBottom == 0" style="text-align:center;">暂无数据</span>
        </div> -->
      </div>
    </div>
    <!-- 清空回收站对话框 -->
    <el-dialog
      custom-class="clearAllDialog"
      title="提示"
      :visible.sync="dialogVisible"
      center
      top="30vh"
      width="439px"
    >
      <span class="info">{{ info }}</span>
      <div class="dialog">
        <el-button style="margin-left: 88px" @click="confirm">确 定</el-button>
        <el-button style="margin-left: 57px" @click="dialogVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import noResult from '../component/noResult.vue'
export default {
  inject:['reload'],
  components: {
      noResult,
  },
  data() {
    return {
      // 显示隐藏清空对话框
      dialogVisible: false,
      // 对话框文本信息
      info: "",
      // 显示隐藏删除对话框
      // deleteVisible: false,

      listData: [], //用于显示的数据
      judgeBottom: 2, //底部显示, 0 暂无数据 1 正在加载 2 上滑刷新
      pageCount: 1, //分页请求的页码
      totalCount: 0, //返回的详情的总条数
      //totalCount是每次请求后后端返回的存在与数据库中符合条件的数据条数总数
      limitCount: 20, //分页请求一次请求的数据条数
      ip_id: ""
    };
  },
  computed: {
    recyclePage:{
      get() {
        return this.$store.state.recycle
      },
      set(val){
          // this.$store.commit('recyclePage', val)
      }

    }
  },
  watch: {
    recyclePage(val) {
      if(val == true){
        this.reload();
      }
    }
  },
  created () {
    this.$store.commit('recyclePage', false)
    this.getRecycleBinList()
  },

  //添加滑动监听事件,同时将data中的变量pageCount,limitCount,totalCount赋值给触发函数,
  //同时设置定时器2000ms触发一次,用于函数节流
  mounted () {
    let scrollContent = this.$refs.scrollContent
    scrollContent.onscroll = this.throttle(() => {
      this.scrollBottom(this.pageCount, this.limitCount, this.totalCount)
    }, 2000) //滑动则触发事件,2000毫秒响应一次
  },
  methods: {
    // 清空回收站按钮
    // clearAll(title) {
    //   this.info = title;
    //   this.dialogVisible = true;
    // },
    // 删除按钮事件
    Delete(ip_id,title) {
      this.info = title
      this.dialogVisible = true
      this.ip_id = ip_id
    },
    // 对话框确认事件
    confirm() {
      this.dialogVisible = false;
      // this.deleteVisible = true;
      this.emptyTrash(this.ip_id)
    },
    getRecycleBinList () {
      this.pageCount = 1
      this.totalCount = 0
      this.$nextTick(()=>{
        this.$refs.scrollContent.scrollTop=0
      })

      this.$http({
        method: 'GET',
        url: '/vw_material/recycle_bin_list/',
        params: {
          page: this.pageCount,
          page_num: this.limitCount
        }
      }).then(res => {
        if (res.data.status === 0) {
            this.listData = res.data.result
            this.pageCount += 1
            this.totalCount = res.data.total //总条数
            if (res.data.result.length < this.limitCount) {
                //如果第一次请求的数据数组的长度没小于每次规定返回的数据条数,表示后端没有多余的值
              this.judgeBottom = 0  //底部显示暂无数据
            }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    },

    // 向上滑动监听，如果pageCount*limitCount的值小于totalCount,
    // 则表示数据库中还有值未请求，可以继续请求。
    // 需要一次判断请求的数据存在为[]，代表数据库中已经没有对应的值了则显示暂无数据
    scrollBottom(pageC, limitC, totalCount) {
      let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 100//距离底部200px时则开始触发函数
      let loadStat = 1 //赋值,表示显示正在加载
      this.judgeBottom = loadStat //显示正在加载
      if (
        bottomOfWindow &&
        loadStat == 1 &&
        (pageC - 1) * limitC < totalCount
        //如果此时到了滑动底部&&底部显示正在加载&&请求过的总数据小于后台总数据
      ) {
        this.pageCount += 1 //页码加一,为了保证取到下次数据
        this.$http({
        method: 'GET',
        url: '/vw_material/recycle_bin_list/',
        params: {
          page: pageC,
          page_num: limitC
        }
      }).then(res => {
        let temp = this.listData
          let len = res.data.result.length
          if (len === 0) {
            // 如果请求数据数组为0,表示数据库没有对应的值了
            loadStat = 0 //显示 暂无数据
          }else if (res.data.status === 0 && temp !== undefined) {
            let arrT = res.data.result  //
            temp.push.apply(temp, arrT)
            //新请求到的数据数组和之前的数据数组合并为一个数组
            //同时tmp和listData的地址引用是同一个
            //所以tmp指向的数组改变，listData数组也发生了改变
            loadStat = 2   //表示 向上刷新
        } else {
          loadStat = 0   //表示 暂无数据
          // this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
      } else if ((pageC - 1) * limitC >= totalCount) {
        // (页码数-1)*每次请求返回数 >= 总条数
        //代表拿到数据库中的所有数据了，
        //之所以-1是为了防止存在剩下的数据条数不足一个limitC,所以会导致不发送请求
        loadStat = 0
      } else {
        loadStat = 2
      }
      this.judgeBottom = loadStat //修改loading显示
    },

     //公用定时器 , 用于函数节流，防止多次触发监听
    throttle(fn, delay) {
      let timer = null
      let firstTime = true
      return function(...args) {
        if (firstTime) {
          // 第一次加载
          fn.apply(this, args)
          return (firstTime = false)
        }
        if (timer) {
          // 定时器正在执行中，跳过
          return
        }
        timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          fn.apply(this, args)
        }, delay)
      }
    },

    emptyTrash (ip_id) {
      const formData = new FormData()
      formData.append("ip_id",ip_id)
      this.$http({
        method: 'POST',
        url: '/vw_material/empty_the_recycle_bin/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.getRecycleBinList()
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    },
    recoverFile (ip_id) {
      const formData = new FormData()
      formData.append("ip_id",ip_id)
      this.$http({
        method: 'POST',
        url: '/vw_material/restore_ip/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.getRecycleBinList()
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    }


  },
  beforeDestroy() {
    //销毁监听事件,由于是单页面应用,不销毁的话监听事件会一直存在
    let scrollContent = this.$refs.scrollContent
    scrollContent.removeEventListener('scroll', this.scrollBottom,true)
  },
  destroyed (){
    this.$store.commit('recyclePage', false)
  }

};
</script>

<style lang="scss" scoped>
.recycle {
  // 清空回收站按钮及对话框样式
  #header {
    .el-button {
      width: 96px;
      height: 30px;
      line-height: 30px;
      background: #2D2C39;
      border-radius: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.9);
      border: 0px solid #dcdfe6;
      padding: 0;
    }
    .opacity{
      opacity:0.6;
　　　filter:alpha(opacity=60);  //filter 过滤器   兼容IE678
    }
  }
  //回收站内容
  .context {
    // 头部行
    padding: 40px 3.5vw 0 3.2vw;
    .headtitle {
      text-align: center;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      .el-divider {
        background-color: #3e3d4c;
        margin: 30px 0px 0px 0px;
      }
    }
    .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin: 0;
        background: #3e3d4c;
      }
    // 内容行的容器
    .contextcontainer {
      // padding-top: 25px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 70vh;
      font-size: 14px;
      font-family: Microsoft YaHei;
      color: rgba(255, 255, 255, 0.9);
      // 内容行
      .contextItme {
          #item{
            height: 116px;
            display: flex;
            align-items: center;
            text-align: center;
          }
        .el-row {
          text-align: center;
          .metirial_name {
              display: block;
              // 多行文本超出显示...
              // display: -webkit-box;
              // -webkit-box-orient: vertical;
              // -webkit-line-clamp: 4;
              // overflow: hidden; //盒子溢出隐藏
              // word-break: break-all;
            }
        }
      }
    }
    // 内容行的滚动条
    .contextcontainer::-webkit-scrollbar {
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }
    .contextcontainer::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 7px;

      background: #b9bac9;
    }
    .contextcontainer::-webkit-scrollbar-track {
      /*滚动条里面轨道*/

      border-radius: 7px;
      background: #5e5d6e;
    }
  }
  /deep/.clearAllDialog {
    background: #292939;
    border-radius: 15px;
    box-sizing: content-box;
    // width: 439px !important;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
    height: 226px;
    .el-dialog__header {
      letter-spacing: 30px;
      padding: 8px 0px 0px 13px;
      .el-dialog__title {
        color: rgba(255, 255, 255, 0.9);
      }
    }
    .el-dialog__headerbtn {
      display: none;
    }
    .el-dialog__body {
      padding: 0px;
      color: #606266;
      width: 397px;
      height: 162px;
      background: #0F0F1E;
      margin-top: 7px;
      margin-left: 22px;
      border-radius: 0 0 5px 5px;
      .info {
        padding-top: 45px;
        padding-left: 88px;
        color: rgba(255, 255, 255, 0.9);
        display: block;
        text-align: left;
      }
      .dialog {
        display: flex;
        .el-button {
          width: 80px;
          height: 30px;
          background: #0F0F1E;
          border: 1px solid #5e5f72;
          opacity: 0.86;
          border-radius: 20px;
          margin-top: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
}
</style>

<style lang="scss">
// 去掉dialog 的默认padding-right:17px;
.el-popup-parent--hidden{
  padding-right:unset !important;
}
.recycle_tooltip.el-tooltip__popper{
  max-width: 18%;
  line-height: 20px;
}
</style>
